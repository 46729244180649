.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.content-right-title {
	font-family: "Roboto", sans-serif;
	font-size: 1.375rem;
	font-weight: 700;
	color: var(--sky-blue);
	text-transform: uppercase;
	margin-top: 0;
}

.content-right-info {
	width: 100%;
	border-top: 1px solid var(--shadow-gray);
	margin-top: 1rem;
}

.content-right-info img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.content-right-info p {
	font-family: "Roboto", sans-serif;
	font-size: 1rem;
	color: var(--black);
}

.content-right-info-item {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid var(--shadow-gray);
}

.content-right-container-number {
	flex: 0 0 2.5rem;
	height: auto;
	margin: 0.75rem 0 0 0;
}

.content-right-container-number h2 {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-size: 2rem;
	color: var(--sky-blue);
	display: inline;
	position: relative;
	margin: 0;
}

.content-right-info-item p {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.3rem;
	color: var(--black);
	display: inline;
	position: relative;
	margin: 0;
	padding: 1.2rem 0;
}
.content-right-info-item p:hover {
	color: var(--sky-blue);
}

.content-right-list {
	display: inline-block;
	margin: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 0;
	margin-bottom: 0;
}

.content-right-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-width: 768px) {
	.content-right-info {
		border-top: none;
	}
	.content-right-info-item p {
		font-size: 1rem;
		font-weight: 400;
	}
}

@media (max-width: 480px) {
	.content-right-info-item p {
		font-size: 1rem;
		line-height: 1.2rem;
	}
}
